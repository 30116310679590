@import 'src/core/styles/media';
@import 'src/core/styles/mixins';

.gallery-item {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @include media(tablet) {
    margin: 1rem;
    width: calc(50% - 2rem);

    // &:last-child {
    //   margin-right: 0;
    // }
  }

  @include media(desktop) {
    // margin-right: 1rem;
    width: calc(33.3333% - 2rem);

    // &:last-child {
    //   margin-right: 0;
    // }
  }

  &__field {
    height: 100%;
    width: 100%;
  }
}
