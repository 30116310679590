@import 'src/core/styles/mixins';

.game-is-over-form {
  user-select: none;

  .title {
    line-height: 150%;

    &--winner {
      color: $golden;
      font-weight: bold;
    }
  }

  .record-line {
    padding: 0.5rem 0;
  }

  .score-info {
    @include flexBox(row, nowrap, space-between, center);
  }

  .big-text {
    font-size: 1.25rem;
    font-weight: bold;
  }
}