@import 'src/core/styles/media';
@import 'src/core/styles/mixins';

.field {
  height: 100%;
  overflow: hidden;
  padding: 0 1rem;
  width: 100%;

  // @include flexBox(column, nowrap, flex-start, center);

  @include media(desktop) {
    max-width: calc(800px + 2rem);
  }

  &-toolbar {
    margin-bottom: 1rem;
    width: 100%;
  }

  &-touch-screen-toolbar {
    margin-bottom: 1rem;
    width: 100%;
  }

  &-content {
    // overflow: hidden;
    width: 100%;

    // @include flexBox(column, nowrap, flex-start, center);

    .canvas-wrapper {
      background-color: $lightGrey;
      border-radius: 0.25rem;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem;
      position: relative;
      width: 100%;

      .canvas {
        background-color: $darkBrown;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }
  }
}
