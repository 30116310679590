@import 'src/core/styles/mixins';

.toolbar {
  color: $transparentWhite75;
  font-size: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  @include flexBox(row, nowrap, space-around, center);

  &-item {
    display: inline-block;

    &--great {
      color: $golden;
    }

    &--warn {
      color: $red;
    }
  }
}
